<ng-template #menuItemTemplate let-item="item" let-last="last">
    <ng-container *ngIf="!item.hidden">
        <ng-container *ngIf="!item.feature || (item.feature | hasFeature)">
            <ng-container *ngIf="item.subMenu?.length && !item.backButton">
                <li class="dropdown-header" *hasPrivilege="item.privilege">
                    <ng-container
                        *ngTemplateOutlet="menuItem; context: { item: item }"
                    ></ng-container>
                </li>
            </ng-container>
            <li
                *ngIf="
                    item.linkedMenu$?.value ||
                    item.linkedMenu?.length ||
                    item.backButton
                "
            >
                <button
                    class="dropdown-item {{
                        item.style ? 'text-' + item.style : ''
                    }}"
                    [attr.data-cy]="item.cySelector"
                    [class.active]="
                        item
                            | fn
                                : isSelected
                                : selectedMenuItemValues
                                : selectedMenuItemValue
                                : isMulti
                    "
                    [disabled]="item.disabled"
                    (click)="manageLinkedMenu(item)"
                >
                    <ng-container
                        *ngTemplateOutlet="menuItem; context: { item: item }"
                    ></ng-container>
                </button>
            </li>
            <li
                *ngIf="
                    !item.subMenu?.length &&
                    !item.linkedMenu?.length &&
                    !item.linkedMenu$?.value
                "
            >
                <button
                    *ngIf="item.routerLink; else noRouterLinkButton"
                    ngbDropdownItem
                    class="dropdown-item {{
                        item.style ? 'text-' + item.style : ''
                    }} link"
                    routerLinkActive="active"
                    [attr.data-cy]="item.cySelector"
                    [class.border-bottom-0]="last"
                    [class.active]="
                        item
                            | fn
                                : isSelected
                                : selectedMenuItemValues
                                : selectedMenuItemValue
                                : isMulti
                    "
                    [routerLink]="item.routerLink"
                    [queryParams]="item.queryParams"
                    [disabled]="item.disabled"
                    (click)="onClick(item)"
                >
                    <ng-container
                        *ngTemplateOutlet="menuItem; context: { item: item }"
                    ></ng-container>
                </button>
                <ng-template #noRouterLinkButton>
                    <button
                        ngbDropdownItem
                        class="dropdown-item {{
                            item.style ? 'text-' + item.style : ''
                        }}"
                        [attr.data-cy]="
                            item.cySelector || item.id || item.value
                        "
                        [class.border-bottom-0]="last"
                        [dynamicFormSetup]="item.formSetup"
                        [modalId]="item.modalId"
                        [disabled]="item.disabled"
                        (click)="onClick(item)"
                        *hasPrivilege="item.privilege"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                menuItem;
                                context: { item: item }
                            "
                        ></ng-container>
                    </button>
                </ng-template>
            </li>
            <ul
                *ngIf="item.subMenu?.length"
                class="dropdown-menu dropdown-flush ps-2"
            >
                <ng-container
                    *ngFor="let subItem of item.subMenu; let last = last"
                >
                    <ng-container
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            item: subItem,
                            last: last
                        }"
                    ></ng-container>
                </ng-container>
            </ul>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #menuItem let-item="item">
    <div class="d-flex">
        <atom-avatar
            *ngIf="item.avatar"
            class="dropdown-item__avatar flex-shrink-0 me-2"
            size="md"
            [avatar]="item.avatar"
        ></atom-avatar>
        <wdx-icon
            *ngIf="(item.icon || ghostIcons) && !hideIcons && !item.icon?.stack"
            class="dropdown-item__icon flex-shrink-0 me-2"
            [class.invisible]="!item.icon && ghostIcons"
            [ngClass]="item.iconSeverity | severity : ['text']"
            [icon]="item.icon"
            [scale]="1"
            [fixedWidth]="true"
        ></wdx-icon>

        <wdx-icon-stack *ngIf="item.icon?.stack" class="me-0 stack-icon">
            <wdx-icon
                *ngFor="let icon of item.icon.stack; trackBy: trackByFn"
                class="dropdown-item__icon flex-shrink-0"
                [rotate]="icon.rotate"
                [offsetTop]="icon.offsetTop"
                [offsetLeft]="icon.offsetLeft"
                [scale]="icon.scale"
                [icon]="icon.icon + ' fa-stack-1x '"
                [fixedWidth]="true"
            ></wdx-icon>
        </wdx-icon-stack>

        <a
            *ngIf="item.externalUrl"
            class="flex-grow-1 text-truncate"
            target="_blank"
            [href]="item.externalUrl"
        >
            {{ item.label }}
        </a>
        <div *ngIf="!item.externalUrl" class="flex-grow-1 text-truncate">
            <div
                class="text-truncate"
                [attr.data-cy]="'item-' + (item.id ? item.id : item.value)"
            >
                {{
                    item.translationKey
                        ? (item.translationKey | translate)
                        : (item.label | translateTokenisedString)
                }}
            </div>
            <div *ngIf="item.subLabels" class="small text-truncate">
                <ng-container
                    *ngFor="let subLabel of item.subLabels; let last = last"
                >
                    <span> {{ subLabel }} </span>
                    <span *ngIf="!last" class="inline-bullet"></span>
                </ng-container>
            </div>
        </div>
        <div class="flex-shrink-0 d-flex align-items-center">
            <atom-severity-indicators
                *ngIf="item.severityIndicators?.length"
                [indicators]="item.severityIndicators"
            ></atom-severity-indicators>
            <wdx-icon
                *ngIf="
                    highlightSelected &&
                    showCheckOnHighlight &&
                    !item.linkedMenu &&
                    !item.linkedMenu$
                "
                class="ms-2"
                size="sm"
                icon="check"
                [class.invisible]="
                    !(
                        item
                        | fn
                            : isSelected
                            : selectedMenuItemValues
                            : selectedMenuItemValue
                            : isMulti
                    )
                "
            ></wdx-icon>
            <wdx-icon
                *ngIf="item.linkedMenu || item.linkedMenu$"
                class="ms-2"
                size="sm"
                icon="chevron-right"
            ></wdx-icon>
        </div>
    </div>
</ng-template>

<div
    data-cy="context-menu"
    *ngFor="let activeMenu of activeMenus; let i = index"
    [class.d-none]="i !== activeMenus.length - 1"
>
    <ng-container *ngFor="let menuItem of activeMenu; let last = last">
        <ng-container
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{ item: menuItem, last: last }"
        ></ng-container>
    </ng-container>
</div>
